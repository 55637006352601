import AxiosInstance from './axiosInstance';

const SERVER_URL = process.env.REACT_APP_URL_AUTHENTICATION;

// Function that will be called to refresh authorization
export const SignIn = data => {
  return AxiosInstance.post(`${SERVER_URL}/authentication/`, data);
};

export const Echo = () => {
  return AxiosInstance.get(`${SERVER_URL}/echo/`, { handlerEnabled: true });
};
