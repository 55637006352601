import React, { useState, useEffect, Suspense } from "react";
import Viamobi from "@viamericas/viamobi-component";
import Cookies from "universal-cookie";
import i18n from "./i18n";
import { withTranslation } from "react-i18next";
import upload from './assets/images/document-cloud-white.svg' ;
import uploadDisabled from './assets/images/document-cloud.svg';
import uploadFile from './assets/images/upload.svg';
import uploadCamera from './assets/images/webcam-dark.svg';
import cameraDisabled from './assets/images/webcam.svg';
import camera from './assets/images/webcam-white.svg';
import photo from './assets/images/icon-scan.svg';
import  rotate from './assets/images/rotate.svg';
import userDisabled from './assets/images/user.svg';
import user from './assets/images/user-white.svg';
import cardDisabled from './assets/images/personalcard.svg';
import card from './assets/images/personalcard-white.svg';
import closeCircle from './assets/images/close-circle.svg';
import tickCircle from './assets/images/tick-circle.svg';
import { SignIn } from './security/authenticationAPI';


const viamericasLogo = require("./assets/images/Viamericas_Logo.png");
const pdfDefaultPhoto = require("./assets/images/pdfDefault.png");

const cookies = new Cookies();

function App({
  optionQS,
  agencyQS,
  orderQS,
  amountQS,
  language,
  idSenderGlobal,
  nameSender,
  iddoc,
  env,
  region,
  iris,
  selfie,
  idShortener,
  isBackCapture,
  documentTypeId,
  username,
  reqsource
}) {
  const [idBranch, setIdBranch] = useState(agencyQS || "");
  const [order] = useState(orderQS || "");
  const [amount] = useState(amountQS || " ");
  const [phone, setPhone] = useState("");
  const [activeProcess, setActiveProcess] = useState([]);
  const [token] = useState(process.env.REACT_APP_TOKEN_PASS);
  const [environment] = useState(env || "A" );
  const [r] = useState(region || "" );
  const [isFly, setIsFly] = useState(true);
  const [idVerificationParams, setIdVerificationParams]=useState({})
  let url = process.env.REACT_APP_URL_VIACHEK_MOBILE.replace("{NAME_REGION}",r );

  
  

  if (language !== i18n.language) {
    i18n.changeLanguage(language);
  }

  const setIdBranchData = (data) => {
    setIdBranch(data);
  };

  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  useEffect(() => {
    setPhone(cookies.get("phoneViaMobi"));
  }, []);

  useEffect(() => {
    if (!agencyQS) {
      setIdBranch(cookies.get("agencyViaMobi"));
    }

    if (!optionQS) {
      setActiveProcess(["ID", "PD" /* , 'OF' */]);
    } else {
      setActiveProcess(optionQS.toUpperCase().split(","));
    }
  }, [agencyQS, optionQS]);

  useEffect(() => {
    if (idBranch?.length === 6) {
      cookies.set("agencyViaMobi", idBranch, {
        path: "/",
        maxAge: 60 * 60 * 24 * 365,
        sameSite: true,
      });
    }
  }, [idBranch]);

  useEffect(() => {
    if (phone?.length === 10 || !phone) {
      cookies.set("phoneViaMobi", phone, {
        path: "/",
        maxAge: 60 * 60 * 24 * 365,
        sameSite: true,
      });
    }
  }, [phone]);

  const getToken = async () => {
    localStorage.setItem('user', '');
    localStorage.setItem('token', '');
    localStorage.setItem('refresh_token', '');

    const data = {
      branch: process.env.REACT_APP_TOKEN_BRANCH_API,
      password:  process.env.REACT_APP_TOKEN_PASS_API,
      username: process.env.REACT_APP_TOKEN_USER_API,
      deviceKey: ''
    };

    const response = await SignIn(data);

    localStorage.setItem('user', data.username);
    localStorage.setItem('token', response.data.id_token);
    localStorage.setItem('refresh_token', response.data.refresh_token);

    idVerificationParams.token = 'Bearer '+response.data.id_token;

    setIdVerificationParams( {
      username: username,
      reqsource: reqsource,
      url: process.env.REACT_APP_URL_IDS_VERIFICATIONS.replace("{NAME_REGION}",r ),
      token:'Bearer '+response.data.id_token
     }  )


    setIsFly(false);
  };
  useEffect(() => {
    getToken();
  }, []);

  if (isFly) {
    return '';
  }

  /* 

  useEffect(() => {
    getToken()
      .then((data) => {
        setToken(data);
      })
      .catch((e) => {
        console.error("ERROR BUSCANDO TOKEN", e);
      });
  }, []);

  */ 

  return (
    <Suspense fallback={<div>Loading...</div>}>
     <Viamobi
        idBranch={idBranch}
        setIdBranch={setIdBranchData}
        orderParam={order}
        amountParam={amount}
        phone={phone}
        setPhone={setPhone}
        idSenderGlobal={idSenderGlobal}
        nameSender={nameSender}
        iddoc={iddoc}
        viamericasLogo={viamericasLogo}
        unknowPhoto={uploadCamera}
        pdfDefaultPhoto={pdfDefaultPhoto}
        isMovile={isMobile()}       
        activeProcess={activeProcess}
        token={token}
        url={url}
        env={ (environment === "beta" ? "B": ( environment === "prod" ? "P":"A") )}
        icons={{
          upload,
          uploadDisabled,
          uploadFile,
          camera,
          photo,
          rotate,
          cameraDisabled,
          userDisabled,
          user,
          card,
          cardDisabled,
          uploadCamera,
          closeCircle,
          tickCircle
        }}
        isIrisCapture={iris==='Y' ? true : false}        
        isSelfieCapture={selfie==='Y' ? true : false}
        isBackCapture={isBackCapture==='Y' ? true : false}
        idShortener={idShortener}
        documentTypeId={documentTypeId}
        idVerificationParams={idVerificationParams}
      />
    </Suspense>
  );
}

export default withTranslation()(App);
