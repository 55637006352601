/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
import axios from 'axios';

// https://dev.to/teroauralinna/global-http-request-and-response-handling-with-the-axios-interceptor-30ae
// Constant with authentication server
const SERVER_URL = process.env.REACT_APP_URL_AUTHENTICATION;

const axiosInstance = axios.create();

// detect if handler is enable

// Intercept request in order to add token header
const requestHandler = request => {
  if (!request.url.includes('authentication')) {
    // Modify request here
    const token = localStorage.getItem('token');

    if (token != null || token !== undefined) {
      request.headers.Authorization = `Bearer ${token}`;
    }
  }
  return request;
};

axiosInstance.interceptors.request.use(
  config => requestHandler(config),
  err => {
    return Promise.reject(err);
  }
);

const responseHandler = error => {
  const originalRequest = error.config;
  const status = error.response ? error.response.status : null;
  // Validar si definitivamente caducó el token
  if (status === 401 && originalRequest.url.includes('authentication')) {
    return Promise.reject(error);
  }

  if (status === 401 && !originalRequest.url.includes('authentication')) {
    // boolean para que no se quede en un ciclo infinito al obtener de nuevo el token
    originalRequest._retry = true;

    return axios
      .post(`${SERVER_URL}/authentication/refresh`, {
        username: localStorage.getItem('user'),
        refreshToken: localStorage.getItem('refresh_token')
      })
      .then(res => {
        if (res.status === 200) {
          const newToken = res.data.id_token;
          localStorage.setItem('token', newToken);
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return axios(originalRequest);
        }
      })
      .catch(() => {
        alert('The security token has expired, we reload the page.');
        window.location.reload();
      });
  }

  // return Error object with Promise
  return Promise.reject(error);
};

// https://medium.com/swlh/handling-access-and-refresh-tokens-using-axios-interceptors-3970b601a5da
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => responseHandler(error)
);

export default axiosInstance;
